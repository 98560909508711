import {BrowserRouter} from "react-router-dom"
import {useAppSelector} from "@features/redux/store"
import {getMe} from "@features/auth/slice"
import {lazy, LazyExoticComponent, useMemo} from "react"
import {TwoFactorAuthScreen} from "@components/common/screens/TwoFactorAuthScreen/TwoFactorAuthScreen"
import {CookiesAlert} from "@components/CookiesAlert/CookiesAlert";

const GuestRoutes = lazy(() => import("@helpers/routes/guest"));
const AdminRoutes = lazy(() => import("@helpers/routes/admin"));
const UserRoutes = lazy(() => import("@helpers/routes/user"));
const InspectorRoutes = lazy(() => import("@helpers/routes/inspector"));

const roleToRouteMap: Record<string, LazyExoticComponent<any>> = {
    super_admin: AdminRoutes,
    admin: AdminRoutes,
    inspector: InspectorRoutes,
    user: UserRoutes,
}

export const App = () => {
    const user = useAppSelector(getMe)

    const Route = useMemo(() => {
        if (!user?.role) {
            return GuestRoutes;
        }

        return roleToRouteMap[user?.role?.name];
    }, [user]);

    // const routes = useMemo(() => (
    //     <TwoFactorAuthScreen>
    //         <AdminRoutes/>
    //         <InspectorRoutes/>
    //         <UserRoutes/>
    //         <GuestRoutes/>
    //         <CookiesAlert/>
    //     </TwoFactorAuthScreen>
    // ), [user])

    return (
        <BrowserRouter>
            <TwoFactorAuthScreen>
                {<Route />}
                <CookiesAlert/>
            </TwoFactorAuthScreen>
        </BrowserRouter>
    )
}
